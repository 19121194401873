import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'

// The redirection is done manually on the auth middleware because
// we don't want the staff to be redirected to the client login page
const commonRedirectionConfig = {
  auth: { required: true, redirection: { name: ROUTES.ADMIN_TOOLS.HOME } },
  staff: { required: true },
}

const boStaffRoutes = [
  {
    name: ROUTES.BO_STAFF.INSURANCES.CANCEL,
    path: '/bo-staff/insurances/:policyId/cancel',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.BACKSHIP.TRADELINES,
    path: '/bo-staff/backship/tradelines',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.BACKSHIP.CATEGORY_PARCEL_DIMENSIONS,
    path: '/bo-staff/backship/category-parcel-dimensions',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.BACKSHIP.PROMISE_BUFFER_DISTURBANCES,
    path: '/bo-staff/backship/promise-buffer-disturbances',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.BACKSHIP.BILLING.CARRIER_INVOICES,
    path: '/bo-staff/backship/billing/carrier-invoices',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.BACKSHIP.BILLING.SELLER_CHARGES,
    path: '/bo-staff/backship/billing/seller-charges/:invoiceId',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.BACKSHIP.BILLING.MARGINS_CONFIGURATION
      .CARRIER_BACKSHIP_MARGINS,
    path: '/bo-staff/backship/billing/margins-configuration/carrier-backship-margins',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.BACKSHIP.BILLING.MARGINS_CONFIGURATION
      .SELLER_BACKSHIP_MARGINS,
    path: '/bo-staff/backship/billing/margins-configuration/seller-backship-margins',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.BACKSHIP.BILLING.MARGINS_CONFIGURATION
      .TRADELINE_BACKSHIP_MARGINS,
    path: '/bo-staff/backship/billing/margins-configuration/tradeline-backship-margins',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CATALOG.SALES.CATEGORIES.RESTRICTED.LIST,
    path: '/bo-staff/catalog/sales/categories/restricted',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.TOOLS.TASK_ANALYZER,
    path: '/bo-staff/tools/task-analyzer',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.TOOLS.FIELD_VALIDATION_PLAYGROUND,
    path: '/bo-staff/tools/field-validation-playground',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.TOOLS.LIVE_IDENTITY_CHECK,
    path: '/bo-staff/live-identity-check',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.SALES.RATINGS_REVIEWS.OVERVIEW,
    path: '/bo-staff/ratings-reviews',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.SALES.RATINGS_REVIEWS.MODERATION,
    path: '/bo-staff/ratings-reviews/moderation',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.SALES.SALES_LIMITATIONS,
    path: '/bo-staff/sales-limitations',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.FINANCE.CREDIT_REQUESTS,
    path: '/bo-staff/finance/credit-requests',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CATALOG.SALES.PRODUCTS.UPDATE,
    path: '/bo-staff/catalog/sales/product/update/:productId',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CATALOG.VALIDATION.PRODUCTS.LIST,
    path: '/bo-staff/catalog/validations/products',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CATALOG.VALIDATION.PRODUCTS.REMATCH.ITEM,
    path: '/bo-staff/catalog/validations/rematch/products/:productId',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CATALOG.VALIDATION.LISTINGS.REMATCH,
    path: '/bo-staff/catalog/validations/rematch/listings/:productId/:productIdToCompare',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CUSTOMERS.INDEX,
    path: '/bo-staff/customers',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CATALOG.TOOLS.BULK_ACTIONS,
    path: '/bo-staff/catalog/tools/bulk-actions',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CATALOG.VALIDATION.PRODUCTS.HISTORY,
    path: '/bo-staff/catalog/validations/products/history',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CATALOG.SALES.LISTINGS.LIST,
    path: '/bo-staff/catalog/sales/listings',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CATALOG.SALES.LISTINGS.LIFECYCLE.INDEX,
    path: '/bo-staff/catalog/sales/listings/:listingId/lifecycle/marketized-attributes',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CATALOG.SALES.LISTINGS.LIFECYCLE.ONLINE_CONDITIONS,
    path: '/bo-staff/catalog/sales/listings/:listingId/lifecycle/online-conditions',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.CATALOG.SALES.LISTINGS.LIFECYCLE.HISTORY,
    path: '/bo-staff/catalog/sales/listings/:listingId/lifecycle/history',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.BUYBACK.ORDERS.INDEX,
    path: '/bo-staff/buyback/orders/:orderId',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_STAFF.BUYBACK.ORDERS.CONVERSATION,
    path: '/bo-staff/buyback/orders/:orderId/conversation',
    meta: { disableClientSideNavigation: true },
  },
]

const routes = ({ config }) => {
  return [
    /**
     * Tools
     */
    {
      name: ROUTES.ADMIN_TOOLS.TOOLS.TASK_ANALYZER,
      path: 'tools/task_analyzer',
      component: () =>
        dynamicImport(import('@admin-tools/modules/tools/pages/task-analyzer')),
      meta: {
        ...commonRedirectionConfig,
        ...insertIf(config.FF_ENABLE_NEW_TASK_ANALYZER_PAGE, {
          redirectToFrontApps: {
            name: ROUTES.BO_STAFF.TOOLS.TASK_ANALYZER,
          },
        }),
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.TOOLS.FIELD_VALIDATION_PLAYGROUND,
      path: 'tools/field_validation_playground',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/tools/pages/FieldValidationPlayground'),
        ),
      meta: {
        ...insertIf(config.FF_ENABLE_NEW_FIELD_VALIDATION_PLAYGROUND_PAGE, {
          redirectToFrontApps: {
            name: ROUTES.BO_STAFF.TOOLS.FIELD_VALIDATION_PLAYGROUND,
          },
        }),
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.TOOLS.LIVE_IDENTITY_CHECK,
      path: 'live_identity_check',
      meta: {
        redirectToFrontApps: {
          name: ROUTES.BO_STAFF.TOOLS.LIVE_IDENTITY_CHECK,
        },
      },
    },
    /**
     * Catalog
     */
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.TOOLS.BULK_ACTIONS,
      path: 'catalog/tools/bulk_actions',
      meta: {
        ...commonRedirectionConfig,
        redirectToFrontApps: {
          name: ROUTES.BO_STAFF.CATALOG.TOOLS.BULK_ACTIONS,
        },
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.PRODUCTS.LIST,
      path: 'catalog/products',
      component: () =>
        config.KILL_ADMIN_TOOLS_CATALOG_PRODUCTS_SEARCH
          ? dynamicImport(
              import(
                '@admin-tools/shared/components/DeprecatedPage/DeprecatedPage.vue'
              ),
            )
          : dynamicImport(
              import('@admin-tools/modules/catalog/pages/products/search'),
            ),
      meta: commonRedirectionConfig,
    },
    {
      path: 'catalog/products/:akeneoProductId',
      component: () =>
        config.KILL_ADMIN_TOOLS_CATALOG_AKENEO_PRODUCT_DETAILS
          ? dynamicImport(
              import(
                '@admin-tools/shared/components/DeprecatedPage/DeprecatedPage.vue'
              ),
            )
          : dynamicImport(
              import('@admin-tools/modules/catalog/pages/products/single'),
            ),
      meta: commonRedirectionConfig,
      redirect: { name: ROUTES.ADMIN_TOOLS.CATALOG.PRODUCTS.ITEM.AKENEO },
      children: [
        {
          name: ROUTES.ADMIN_TOOLS.CATALOG.PRODUCTS.ITEM.AKENEO,
          path: 'details',
          component: () =>
            dynamicImport(
              import(
                '@admin-tools/modules/catalog/pages/products/single/children/ProductDetails'
              ),
            ),
        },
        {
          path: 'buyback/backboxes',
          name: ROUTES.ADMIN_TOOLS.CATALOG.PRODUCTS.ITEM.BUYBACK.BACKBOXES,
          component: () =>
            dynamicImport(
              import(
                '@admin-tools/modules/catalog/pages/products/single/children/Backboxes'
              ),
            ),
        },
        {
          path: 'buyback/listings',
          name: ROUTES.ADMIN_TOOLS.CATALOG.PRODUCTS.ITEM.BUYBACK.LISTINGS,
          component: () =>
            dynamicImport(
              import(
                '@admin-tools/modules/catalog/pages/products/single/children/Listings'
              ),
            ),
        },
      ],
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.TOOLS.AKENEO.IMAGES.UPLOAD,
      path: 'catalog/tools/akeneo/images/upload',
      component: () =>
        config.KILL_ADMIN_TOOLS_CATALOG_AKENEO_IMAGE_UPLOAD
          ? dynamicImport(
              import(
                '@admin-tools/shared/components/DeprecatedPage/DeprecatedPage.vue'
              ),
            )
          : dynamicImport(
              import(
                '@admin-tools/modules/catalog/pages/tools/akeneo/images/upload'
              ),
            ),
      meta: commonRedirectionConfig,
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.BUYBACK.CATEGORIES.LIST,
      path: 'catalog/buyback/categories',
      component: () =>
        config.KILL_ADMIN_TOOLS_SELLER_BUYBACK_PRODUCT
          ? dynamicImport(
              import(
                '@admin-tools/shared/components/DeprecatedPage/DeprecatedPage.vue'
              ),
            )
          : dynamicImport(
              import('@admin-tools/modules/catalog/pages/buyback/categories'),
            ),
      meta: commonRedirectionConfig,
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.BUYBACK.MERCHANT.PRODUCT.LISTINGS,
      path: 'catalog/buyback/merchants/:merchantId/products/:productId',
      component: () =>
        config.KILL_ADMIN_TOOLS_CATALOG_BUYBACK_LISTINGS_SEARCH
          ? dynamicImport(
              import(
                '@admin-tools/shared/components/DeprecatedPage/DeprecatedPage.vue'
              ),
            )
          : dynamicImport(
              import(
                '@admin-tools/modules/catalog/pages/buyback/merchant/product/listings'
              ),
            ),
      meta: commonRedirectionConfig,
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.BUYBACK.LISTINGS.SEARCH,
      path: 'catalog/buyback/listings/search',
      component: () =>
        config.KILL_ADMIN_TOOLS_CATALOG_BUYBACK_LISTINGS_SEARCH
          ? dynamicImport(
              import(
                '@admin-tools/shared/components/DeprecatedPage/DeprecatedPage.vue'
              ),
            )
          : dynamicImport(
              import('@admin-tools/modules/catalog/pages/buyback/search'),
            ),
      meta: commonRedirectionConfig,
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.VALIDATION.PRODUCTS.HISTORY,
      path: 'catalog/validations/products/history',
      meta: {
        ...commonRedirectionConfig,
        redirectToFrontApps: {
          name: ROUTES.BO_STAFF.CATALOG.VALIDATION.PRODUCTS.HISTORY,
        },
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.VALIDATION.PRODUCTS.LIST,
      path: 'catalog/validations/products',
      component: () =>
        dynamicImport(
          import(
            '@admin-tools/modules/catalog/pages/validations/products/list'
          ),
        ),
      meta: {
        ...commonRedirectionConfig,
        ...insertIf(config.FF_ENABLE_NEW_CATALOG_VALIDATION_PRODUCT_LIST, {
          redirectToFrontApps: {
            name: ROUTES.BO_STAFF.CATALOG.VALIDATION.PRODUCTS.LIST,
          },
        }),
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.VALIDATION.PRODUCTS.REMATCH.ITEM,
      path: 'catalog/validations/rematch/products/:productId',
      component: () =>
        dynamicImport(
          import(
            '@admin-tools/modules/catalog/pages/validations/products/rematch/search'
          ),
        ),
      meta: {
        ...commonRedirectionConfig,
        ...insertIf(
          config.FF_ENABLE_NEW_CATALOG_VALIDATION_PRODUCT_REMATCH_ITEM,
          {
            redirectToFrontApps: {
              name: ROUTES.BO_STAFF.CATALOG.VALIDATION.PRODUCTS.REMATCH.ITEM,
            },
          },
        ),
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.VALIDATION.LISTINGS.REMATCH,
      path: 'catalog/validations/rematch/listings/:productId/:productIdToCompare',
      component: () =>
        dynamicImport(
          import(
            '@admin-tools/modules/catalog/pages/validations/listings/rematch'
          ),
        ),
      meta: {
        ...commonRedirectionConfig,
        ...insertIf(config.FF_ENABLE_NEW_CATALOG_VALIDATION_LISTING_REMATCH, {
          redirectToFrontApps: {
            name: ROUTES.BO_STAFF.CATALOG.VALIDATION.LISTINGS.REMATCH,
          },
        }),
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.CATEGORIES.RESTRICTED.LIST,
      path: 'catalog/sales/categories/restricted',
      meta: {
        ...commonRedirectionConfig,
        redirectToFrontApps: {
          name: ROUTES.BO_STAFF.CATALOG.SALES.CATEGORIES.RESTRICTED.LIST,
        },
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.LISTINGS.LIST,
      path: 'catalog/sales/listings',
      meta: {
        ...commonRedirectionConfig,
        redirectToFrontApps: {
          name: ROUTES.BO_STAFF.CATALOG.SALES.LISTINGS.LIST,
        },
      },
    },
    {
      path: 'catalog/sales/listings/:listingId/lifecycle',
      meta: {
        ...commonRedirectionConfig,
        redirectToFrontApps: {
          name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.LISTINGS.LIFECYCLE.INDEX,
        },
      },
      children: [
        {
          path: 'marketized-attributes',
          name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.LISTINGS.LIFECYCLE.INDEX,
          meta: {
            ...commonRedirectionConfig,
            redirectToFrontApps: {
              name: ROUTES.BO_STAFF.CATALOG.SALES.LISTINGS.LIFECYCLE.INDEX,
            },
          },
        },
        {
          path: 'online-conditions',
          name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.LISTINGS.LIFECYCLE
            .ONLINE_CONDITIONS,
          meta: {
            ...commonRedirectionConfig,
            redirectToFrontApps: {
              name: ROUTES.BO_STAFF.CATALOG.SALES.LISTINGS.LIFECYCLE
                .ONLINE_CONDITIONS,
            },
          },
        },
        {
          path: 'history',
          name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.LISTINGS.LIFECYCLE.HISTORY,
          meta: {
            ...commonRedirectionConfig,
            redirectToFrontApps: {
              name: ROUTES.BO_STAFF.CATALOG.SALES.LISTINGS.LIFECYCLE.HISTORY,
            },
          },
        },
      ],
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.PRODUCTS.UPDATE,
      path: 'catalog/sales/product/update/:productId',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/catalog/pages/sales/product/update'),
        ),
      meta: {
        ...commonRedirectionConfig,
        ...insertIf(config.FF_ENABLE_NEW_CATALOG_PRODUCT_UPDATE, {
          redirectToFrontApps: {
            name: ROUTES.BO_STAFF.CATALOG.SALES.PRODUCTS.UPDATE,
          },
        }),
      },
    },
    /**
     * Customers
     */
    {
      name: ROUTES.ADMIN_TOOLS.CUSTOMERS.INDEX,
      path: 'customers',
      component: () => dynamicImport(import('@admin-tools/modules/customers')),
      meta: {
        auth: commonRedirectionConfig.auth,
        canViewCustomers: { required: true },
        ...insertIf(config.FF_ENABLE_NEW_BO_CUSTOMERS_PAGE, {
          redirectToFrontApps: {
            name: ROUTES.BO_STAFF.CUSTOMERS.INDEX,
          },
        }),
      },
    },

    /** Sales Limitations */
    {
      name: ROUTES.ADMIN_TOOLS.SALES.LIMITATIONS,
      path: 'sales-limitations',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/sales/pages/sales-limitations'),
        ),
      meta: commonRedirectionConfig,
      ...insertIf(config.FF_ENABLE_NEW_SALES_LIMITATIONS_PAGE, {
        redirectToFrontApps: {
          name: ROUTES.BO_STAFF.SALES.RATINGS_REVIEWS.OVERVIEW,
        },
      }),
    },
    {
      name: ROUTES.ADMIN_TOOLS.SALES.RATINGS_REVIEWS,
      path: 'ratings-reviews',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/sales/pages/ratings-reviews'),
        ),
      meta: {
        ...commonRedirectionConfig,
        ...insertIf(config.FF_ENABLE_NEW_SALES_RATINGS_REVIEWS_PAGE, {
          redirectToFrontApps: {
            name: ROUTES.BO_STAFF.SALES.RATINGS_REVIEWS.OVERVIEW,
          },
        }),
      },
    },
    /** Sellers */
    {
      path: 'sellers/:sellerId',
      component: () =>
        config.KILL_ADMIN_TOOLS_SELLER_LISTINGS
          ? dynamicImport(
              import(
                '@admin-tools/shared/components/DeprecatedPage/DeprecatedPage.vue'
              ),
            )
          : dynamicImport(import('@admin-tools/modules/sellers/pages/single')),
      meta: commonRedirectionConfig,
      redirect: {
        name: ROUTES.ADMIN_TOOLS.CATALOG.SELLERS.ITEM.BUYBACK_LISTINGS,
      },
      children: [
        {
          name: ROUTES.ADMIN_TOOLS.CATALOG.SELLERS.ITEM.BUYBACK_LISTINGS,
          path: 'buyback-listings',
          component: () =>
            dynamicImport(
              import(
                '@admin-tools/modules/sellers/pages/single/children/BuybackListings'
              ),
            ),
        },
        {
          name: ROUTES.ADMIN_TOOLS.CATALOG.SELLERS.ITEM.RATE_LIMITING,
          path: 'rate-limiting',
          component: () =>
            dynamicImport(
              import(
                '@admin-tools/modules/sellers/pages/single/children/RateLimiting'
              ),
            ),
        },
      ],
    },

    /**
     * Repair
     */
    {
      path: '/bo_admin/repair/seller/:sellerId',
      name: ROUTES.ADMIN_TOOLS.REPAIR.SELLER,
      component: () =>
        dynamicImport(
          import(
            '@admin-tools/modules/repair/pages/sellerAdmin/sellerAdmin.vue'
          ),
        ),
      meta: commonRedirectionConfig,
    },

    /**
     * Insurances
     */
    {
      name: ROUTES.ADMIN_TOOLS.INSURANCES.CANCEL,
      path: 'insurances/:policyId/cancel',
      component: () =>
        dynamicImport(
          import(
            '@admin-tools/modules/insurances/pages/PolicyCancellationPage.vue'
          ),
        ),
      meta: commonRedirectionConfig,
    },
    {
      name: ROUTES.ADMIN_TOOLS.INSURANCES.STATUS,
      path: 'insurances/:policyId/status',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/insurances/pages/PolicyStatusPage.vue'),
        ),
      meta: commonRedirectionConfig,
    },
    /**
     * Landing Pages Management
     */
    {
      name: ROUTES.ADMIN_TOOLS.LANDING_PAGES.BULK_IMPORTS,
      path: 'landing-pages/bulk-imports',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/landing-pages/pages/bulk-imports'),
        ),
      meta: commonRedirectionConfig,
    },
    /**
     * Finance
     */
    {
      name: ROUTES.ADMIN_TOOLS.FINANCE.PICSOU,
      path: 'finance/picsou',
      component: () =>
        dynamicImport(import('@admin-tools/modules/finance/pages/picsou')),
      meta: {
        ...commonRedirectionConfig,
        ...insertIf(config.FF_ENABLE_NEW_PICSOU_PAGE, {
          redirectToFrontApps: {
            name: ROUTES.BO_STAFF.FINANCE.PICSOU,
          },
        }),
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.FINANCE.REASONS_OLD,
      path: 'finance/reasons',
      component: () =>
        dynamicImport(import('@admin-tools/modules/finance/pages/reasonsOld')),
      meta: commonRedirectionConfig,
    },
    {
      name: ROUTES.ADMIN_TOOLS.FINANCE.REASONS,
      path: 'finance/reasons-new',
      component: () =>
        dynamicImport(import('@admin-tools/modules/finance/pages/reasons')),
      meta: commonRedirectionConfig,
    },
    {
      name: ROUTES.ADMIN_TOOLS.FINANCE.SELLER_COMPENSATIONS,
      path: 'finance/seller-compensations',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/finance/pages/sellerCompensations'),
        ),
      meta: commonRedirectionConfig,
    },
    {
      name: ROUTES.ADMIN_TOOLS.FINANCE.CREDIT_REQUESTS,
      path: 'finance/credit-requests',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/finance/pages/creditRequests'),
        ),
      meta: {
        ...commonRedirectionConfig,
        ...insertIf(config.FF_ENABLE_NEW_CREDIT_REQUESTS_PAGE, {
          redirectToFrontApps: {
            name: ROUTES.BO_STAFF.FINANCE.CREDIT_REQUESTS,
          },
        }),
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.FINANCE.CCBM_FEE_RULES,
      path: 'finance/ccbm-fee-rules',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/finance/pages/ccbmFeeRules'),
        ),
      meta: commonRedirectionConfig,
    },
    {
      name: ROUTES.ADMIN_TOOLS.FINANCE.TAX_COLLECTION,
      path: 'finance/tax-collection',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/finance/pages/taxCollection'),
        ),
      meta: commonRedirectionConfig,
    },
    /**
     * Orders
     */
    {
      name: ROUTES.ADMIN_TOOLS.ORDERS.MANUAL_REFUNDS,
      path: 'orders/manual-refunds',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/orders/pages/manualRefunds'),
        ),
      meta: commonRedirectionConfig,
    },
    /**
     * Backship
     */
    {
      name: ROUTES.ADMIN_TOOLS.BACKSHIP.TRADELINES,
      path: 'backship/billing/tradelines',
      component: () =>
        dynamicImport(import('@admin-tools/modules/backship/pages/tradelines')),
      meta: {
        ...commonRedirectionConfig,
        ...insertIf(config.FF_ENABLE_NEW_TRADELINE_PAGE, {
          redirectToFrontApps: { name: ROUTES.BO_STAFF.BACKSHIP.TRADELINES },
        }),
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.BACKSHIP.CATEGORY_PARCEL_DIMENSIONS,
      path: 'backship/billing/category-parcel-dimensions',
      meta: {
        redirectToFrontApps: {
          name: ROUTES.BO_STAFF.BACKSHIP.CATEGORY_PARCEL_DIMENSIONS,
        },
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.BACKSHIP.PROMISE_BUFFER_DISTURBANCES,
      path: 'backship/billing/promise-buffer-disturbances',
      meta: {
        redirectToFrontApps: {
          name: ROUTES.BO_STAFF.BACKSHIP.PROMISE_BUFFER_DISTURBANCES,
        },
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.BACKSHIP.BILLING.INVOICES,
      path: 'backship/billing/invoices',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/backship/pages/billing/invoices'),
        ),
      meta: {
        ...commonRedirectionConfig,
        ...insertIf(config.FF_ENABLE_NEW_CARRIER_INVOICES_PAGE, {
          redirectToFrontApps: {
            name: ROUTES.BO_STAFF.BACKSHIP.BILLING.CARRIER_INVOICES,
          },
        }),
      },
    },
    {
      name: ROUTES.ADMIN_TOOLS.BACKSHIP.BILLING.SELLER_CHARGES,
      path: 'backship/billing/seller-charges/:invoiceId',
      component: () =>
        dynamicImport(
          import('@admin-tools/modules/backship/pages/billing/sellerCharges'),
        ),
      meta: {
        ...commonRedirectionConfig,
        ...insertIf(config.FF_ENABLE_NEW_SELLER_CHARGES_PAGE, {
          redirectToFrontApps: {
            name: ROUTES.BO_STAFF.BACKSHIP.BILLING.SELLER_CHARGES,
          },
        }),
      },
    },
    {
      path: 'backship/billing/margins-configuration',
      component: () =>
        dynamicImport(
          import(
            '@admin-tools/modules/backship/pages/billing/sellerChargesMargins'
          ),
        ),
      meta: commonRedirectionConfig,
      redirect: {
        name: ROUTES.ADMIN_TOOLS.BACKSHIP.BILLING.SELLER_CHARGES_MARGINS
          .CARRIER_BACKSHIP_MARGINS,
      },
      children: [
        {
          path: 'carrier-backship-margins',
          name: ROUTES.ADMIN_TOOLS.BACKSHIP.BILLING.SELLER_CHARGES_MARGINS
            .CARRIER_BACKSHIP_MARGINS,
          component: () =>
            dynamicImport(
              import(
                '@admin-tools/modules/backship/pages/billing/sellerChargesMargins/CarrierBackshipMargins'
              ),
            ),
          meta: {
            ...insertIf(config.FF_ENABLE_NEW_MARGINS_CONFIGURATION_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_STAFF.BACKSHIP.BILLING.MARGINS_CONFIGURATION
                  .CARRIER_BACKSHIP_MARGINS,
              },
            }),
          },
        },
        {
          path: 'tradeline-backship-margins',
          name: ROUTES.ADMIN_TOOLS.BACKSHIP.BILLING.SELLER_CHARGES_MARGINS
            .TRADELINE_BACKSHIP_MARGINS,
          component: () =>
            dynamicImport(
              import(
                '@admin-tools/modules/backship/pages/billing/sellerChargesMargins/TradelineBackshipMargins'
              ),
            ),
          meta: {
            ...insertIf(config.FF_ENABLE_NEW_MARGINS_CONFIGURATION_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_STAFF.BACKSHIP.BILLING.MARGINS_CONFIGURATION
                  .TRADELINE_BACKSHIP_MARGINS,
              },
            }),
          },
        },
        {
          path: 'seller-backship-margins',
          name: ROUTES.ADMIN_TOOLS.BACKSHIP.BILLING.SELLER_CHARGES_MARGINS
            .SELLER_BACKSHIP_MARGINS,
          component: () =>
            dynamicImport(
              import(
                '@admin-tools/modules/backship/pages/billing/sellerChargesMargins/SellerBackshipMargins'
              ),
            ),
          meta: {
            ...insertIf(config.FF_ENABLE_NEW_MARGINS_CONFIGURATION_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_STAFF.BACKSHIP.BILLING.MARGINS_CONFIGURATION
                  .SELLER_BACKSHIP_MARGINS,
              },
            }),
          },
        },
      ],
    },
  ]
}

export default ({ config }) => {
  return [
    {
      path: '/bo_admin',
      component: () =>
        dynamicImport(import('./shared/components/layouts/Base.vue')),
      children: routes({ config }),
    },
    /**
     * Legacy routes
     */
    {
      name: ROUTES.ADMIN_TOOLS.HOME,
      path: '/bo_merchant/admin_tools/dashboard',
      meta: { disableClientSideNavigation: true },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.PRODUCTS.LIST,
      path: '/bo_merchant/admin_tools/choose_product_to_duplicate',
      meta: { disableClientSideNavigation: true },
    },
    {
      name: ROUTES.ADMIN_TOOLS.SALES.ORDERS,
      path: '/bo_merchant/admin_tools/orders',
      meta: { disableClientSideNavigation: true },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CUSTOMERS.ACCOUNT,
      path: '/bo_merchant/admin_tools/clients',
      meta: { disableClientSideNavigation: true },
    },
    {
      name: ROUTES.ADMIN_TOOLS.SALES.REFUNDS,
      path: '/bo_merchant/admin_tools/refunds',
      meta: { disableClientSideNavigation: true },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CUSTOMERS.PERSONAL_DATA,
      path: '/bm/bo-admin/customer/:customerId/personal-data',
      meta: { disableClientSideNavigation: true },
    },
    {
      name: ROUTES.ADMIN_TOOLS.SOURCING.ORDERS,
      path: '/bo_merchant/admin_tools/sourcing/orders/:orderId',
      meta: { disableClientSideNavigation: true },
    },
    {
      name: ROUTES.ADMIN_TOOLS.SOURCING.ORDERS_LIST,
      path: '/bo_merchant/admin_tools/sourcing/orders/',
      meta: { disableClientSideNavigation: true },
    },
    {
      name: ROUTES.ADMIN_TOOLS.SALES.PROMOTION_CODES,
      path: '/bo_merchant/admin_tools/promotions_codes/',
      meta: { disableClientSideNavigation: true },
    },
    ...boStaffRoutes,
  ]
}
